<template>
  <div class="">
    <!-- 面包屑 -->
    <div class="jiaoyi">
      <!-- 头部 -->
      <div class="headers">
        <!-- 累计收入：￥{{ totalIn }} 累计支出：￥{{ totalOut }} -->
        <div class="totalIn">
          <div class="left">
            <div class="picture"><i class="hy-icon-bumen"></i></div>
          </div>
          <div class="right">
            <div class="top">累计收入:</div>
            <div class="bottom">
              <span>￥</span>
              <span class="text">{{ totalIn }} </span>
            </div>
          </div>
        </div>
        <div class="totalOut">
          <div class="left">
            <div class="picture"><i class="hy-icon-jiage"></i></div>
          </div>
          <div class="right">
            <div class="top">累计支出:</div>
            <div class="bottom">
              <span>￥</span>
              <span class="text">{{ totalOut }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- Echarts -->
      <div class="chart">
        <div class="charts" id="myChart"></div>
        <div class="charts" id="myChart1"></div>
      </div>
      <!-- 搜索 -->
      <div class="search">
        <el-select
          v-model="formBase.tradeType"
          class="select"
          placeholder="请选择类型"
          @change="tradeTypeChange"
        >
          <el-option
            v-for="(item, index) in getTypes.priceType"
            :key="index"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>

        <el-date-picker
          v-model="timeList"
          class="data"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :clearable="false"
        >
        </el-date-picker>

        <el-button
          type="primary"
          class="btn"
          @click="click"
          :disabled="btnChangeEnable"
          >搜索</el-button
        >
        <el-button class="canle" @click="handleResetSearch">清除</el-button>
        <el-button class="" size="mini" @click="handleQueryCode"
          >查询打印校验码</el-button
        >
        <el-button class="" size="mini" @click="handleExportTable"
          >导出</el-button
        >
        <!-- <el-button class="canle" @click="centerDialogVisible = true"
          >打印</el-button
        > -->
      </div>
      <!-- <div class="export">
        
      </div> -->
      <!-- <el-card class="box-card">
      <div class="headers">
        累计收入：￥{{ totalIn }} 累计支出：￥{{ totalOut }}
        <div class="totalIn">
          <div class="left">
            <div class="picture"><i class="el-icon-bumen"></i></div>
          </div>
          <div class="right">
            <div class="top">累计收入</div>
            <div class="bottom">
              <span>￥</span>
              <span class="text">{{ totalIn }} </span>
            </div>
          </div>
        </div>
        <div class="totalOut">
          <div class="left">
            <div class="picture"><i class="el-icon-bumen"></i></div>
          </div>
          <div class="right">
            <div class="top">累计支出</div>
            <div class="bottom">
              <span>￥</span>
              <span class="text">{{ totalOut }} </span>
            </div>
          </div>
        </div>
      </div>
      <div class="chart">
        <div id="myChart" :style="{ width: '800px', height: '400px' }"></div>
        <div id="myChart1" :style="{ width: '800px', height: '400px' }"></div>
      </div>
    </el-card> -->
      <el-card class="box-card">
        <!-- <div slot="header" class="clearfix"> -->
        <!-- <div class="block" style="float: right; padding: 3px 0">
            <el-date-picker
              v-model="timeList"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </div>
          <el-select
            v-model="formBase.type"
            placeholder="请选择价格类型"
            style="width: 200px; float: right; margin: 0 10px"
          >
            <el-option
              v-for="(item, index) in getTypes.priceType"
              :key="index"
              :label="item"
              :value="index + 1"
            ></el-option>
          </el-select>
          <el-button
            style="
              float: right;
              padding: 3px 0;
              width: 76px;
              height: 34px;
              background: linear-gradient(90deg, #2079fb, #4cb2fc);
              border-radius: 4px;
              font-size: 14px;
              font-weight: 500;
              color: #ffffff;
            "
            type="primary"
            @click="click"
            :disabled="btnChangeEnable"
            >搜索</el-button
          >
          <el-button
            style="
              float: right;
              padding: 3px 0;
              width: 76px;
              height: 34px;
              background: #ffffff;
              border-radius: 4px;
              font-size: 14px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #aaaaaa;
            "
            @click="handleResetSearch()"
            >清除</el-button
          > -->
        <!-- </div> -->
        <div class="table-box">
          <el-table
            style="width: 100%"
            :data="record"
            v-loading="listLoading"
            :header-cell-style="{
              'text-align': 'center',
              'background-color': '#DCDCDC',
              color: 'black',
            }"
            :cell-style="cellStyle"
          >
            <el-table-column
              prop="num"
              type="index"
              label="序号"
            ></el-table-column>
            <el-table-column prop="createTime" width="180" label="交易时间">
              <template slot-scope="scope">{{
                scope.row.createTime | formatCreateTime
              }}</template>
            </el-table-column>
            <el-table-column
              prop="tradeAmount"
              width="100%"
              label="交易金额(元)"
            >
              <template slot-scope="scope">{{
                scope.row.tradeAmount
              }}</template>
            </el-table-column>

            <!-- <el-table-column
              prop="afterBalance"
              width="100%"
              label="账户金额(元)"
            >
            </el-table-column> -->

            <el-table-column
              prop="afterBalance"
              width="100%"
              label="账户余额(元)"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.rechargeType == 1">/</div>
                <div v-else>
                  {{ scope.row.afterBalance }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="bankType" label="账户类型">
              <template slot-scope="scope">
                <div v-if="scope.row.bankType == 0">中信账户</div>
                <div v-if="scope.row.bankType == 1">中金账户</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="tradeType"
              class="aaa"
              width="180"
              label="交易类型"
            >
              <template slot-scope="scope">
                <div
                  slot="reference"
                  class="name-wrapper"
                  :class="{ 'table-active': scope.row.tradeType % 2 != 0 }"
                >
                  <el-tag size="medium">{{
                    scope.row.tradeType | formatType
                  }}</el-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="rechargeType" label="交易方式">
              <template
                slot-scope="scope"
                v-if="!(scope.row.tradeType == 13 || scope.row.tradeType == 14)"
              >
                {{ scope.row.rechargeType | formatWay }}
              </template>
            </el-table-column>
            <el-table-column prop="businessStatus" label="状态">
            </el-table-column>
            <el-table-column
              prop="verifyCode"
              label="校验码/凭证"
              v-if="
                (formBase.tradeType && formBase.tradeType == 13) ||
                !formBase.tradeType ||
                (formBase.tradeType && formBase.tradeType == 14)
              "
            >
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  v-if="
                    (scope.row.bankType == 1 &&
                      scope.row.businessStatus == '已到账') ||
                    (scope.row.bankType == 1 &&
                      scope.row.businessStatus == '提现成功')
                  "
                  size="mini"
                  @click="BackClick(scope.row)"
                  >查看</el-button
                >
                <div v-else>{{ scope.row.verifyCode }}</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="verifyCode"
              show-overflow-tooltip
              label="回单"
              v-if="istrue"
            >
              <template slot-scope="scope">
                <el-button
                  @click="handleClick(scope.row.tradeVoucher)"
                  v-if="
                    !(scope.row.tradeType == 13 || scope.row.tradeType == 14)
                  "
                  type="text"
                  size="small"
                  >查看</el-button
                >
              </template>
            </el-table-column>
            <!-- <el-table-column fixed="right" v-if="formBase.tradeType" label="操作" width="100">
              <template slot-scope="scope">
                <el-button
                  @click="handleClick(scope.row)"
                  type="text"
                  size="small"
                  >查看</el-button
                >
              </template>
            </el-table-column> -->
          </el-table>
        </div>
        <!-- 分页 -->
        <el-row type="flex" justify="end" style="height: 50px" align="middle">
          <el-pagination
            background
            layout="total, sizes, prev, pager, next"
            :total="total"
            :current-page="formBase.page"
            :page-size="formBase.size"
            :page-sizes="[10, 20, 50]"
            @current-change="changePage"
            @size-change="handleSizeChange"
            :pagerCount="5"
          />
        </el-row>
      </el-card>
    </div>

    <el-dialog title="" :visible.sync="centerDialogVisible" width="1200px">
      <div class="paymentReceipt">
        <div>
          <div
            style="
              padding: 8px 8px;
              background: #409eff;
              width: 100px;
              text-align: center;
              margin-left: 30px;
              color: #fff;
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;
            "
          >
            支付回单
          </div>
          <div
            style="
              width: 100%;
              height: 3px;
              background: #409eff;
              margin-top: 1px;
            "
          ></div>
        </div>
        <table
          id="table"
          width="50%"
          height="100"
          style="margin-top: 30px; color: #99948c"
        >
          <tr>
            <th colspan="10" style="background: #f3f3f3; height: 30px">
              电子转账凭证
            </th>
          </tr>
          <tr>
            <th
              colspan="10"
              style="text-align: left; padding-left: 10px; height: 40px"
            >
              交易时间：{{ headerobject.tradeTime }}
            </th>
          </tr>
          <tr>
            <th rowspan="2" style="height: 80px">付款方</th>
            <td colspan="1" style="text-align: center">账号</td>
            <td colspan="2" style="text-align: center">
              {{ headerobject.paymentAccount }}
            </td>
            <th rowspan="2">收款方</th>
            <td colspan="1" style="text-align: center">账号</td>
            <td colspan="2" style="text-align: center">
              {{ headerobject.receiveAccount }}
            </td>
          </tr>
          <tr>
            <td colspan="1" style="text-align: center">户名</td>
            <td colspan="2" style="text-align: center">
              {{ headerobject.paymentName }}
            </td>
            <td colspan="1" style="text-align: center">户名</td>
            <td colspan="2" style="text-align: center">
              {{ headerobject.receiveName }}
            </td>
          </tr>

          <tr style="height: 40px">
            <th colspan="2">金额(小写)</th>
            <td style="text-align: center">{{ headerobject.amount }}</td>
            <th colspan="2">金额(大写)</th>
            <td colspan="2" style="text-align: center">
              {{ headerobject.amountInWords }}
            </td>
          </tr>

          <tr style="height: 40px">
            <th colspan="2">币种</th>
            <td style="text-align: center">人民币</td>
            <th colspan="2">交易类型</th>
            <td colspan="2" style="text-align: center">
              {{ headerobject.tradeType }}
            </td>
          </tr>

          <tr style="height: 40px">
            <th colspan="2">交易渠道</th>
            <td style="text-align: center">电子商务</td>
            <th colspan="2">交易行名</th>
            <td colspan="2" style="text-align: center">中信银行</td>
          </tr>

          <tr style="height: 40px">
            <th colspan="2">交易流水号</th>
            <td style="text-align: center">{{ headerobject.tradeBatch }}</td>
            <th colspan="2">交易用途</th>
            <td colspan="2" style="text-align: center">
              {{ headerobject.tradePurpose }}
            </td>
          </tr>

          <tr>
            <th colspan="2">
              <img src="../../../assets/zxx_img/yinhang.png" alt="" />
            </th>
            <td style="text-align: center" colspan="10">
              本回单仅表明您的账户有金融性交易,不能作为到账凭证，不能作为收款方发货依据
            </td>
          </tr>
        </table>
      </div>

      <div style="display: flex; justify-content: center; margin-top: 20px">
        <div>
          重要提示：本回单仅表明您的账户有金融性交易,不能作为到账凭证，不能作为收款方发货依据
        </div>
        <div style="display: flex; margin-left: 20px; color: #409eff">
          <div style="cursor: pointer" @click="downloadResult('支付回执')">
            下载
          </div>
          <div style="margin-left: 10px; cursor: pointer" @click="printDom">
            打印
          </div>
          <div
            style="margin-left: 10px; cursor: pointer"
            @click="centerDialogVisible = false"
          >
            关闭
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="centerDialogVisible = false">取 消</el-button> -->
        <!-- <el-button type="primary" @click="centerDialogVisibleClick"
          >确 定</el-button
        > -->
      </span>
    </el-dialog>

    <el-dialog
      title="查询打印校验码"
      :visible.sync="FlowVerifyCodeDiolog"
      width="800px"
      center
    >
      <div>
        <el-form ref="form" :model="flowVerifyForm" label-width="80px">
          <el-row>
            <el-col :span="18">
              <el-form-item label="时间区间">
                <el-date-picker
                  v-model="flowVerifyForm.FlowVerifyCodeDate"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <a
                target="_blank"
                style="text-decoration-line: underline"
                href="https://corp.bank.ecitic.com/cotb/m000A0201.html"
                >前往中信银行查询页</a
              >
            </el-col>
          </el-row>
        </el-form>
        <el-table
          :data="flowVerifyCodeList"
          style="width: 100%"
          v-if="flowVerifyCodeList.length"
          border
        >
          <el-table-column prop="numberPage" label="页码" align="center">
          </el-table-column>
          <el-table-column prop="verifyCode" label="打印校验码" align="center">
          </el-table-column>
          <el-table-column prop="" label="操作" align="center">
            <template slot-scope="{ row }">
              <el-button type="text" @click="handleCopyVerifyCode(row)"
                >复制校验码</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="getFlowVerifyList" v-if="queryCodeBtn"
          >下一步</el-button
        >
        <el-button type="primary" @click="FlowVerifyCodeDiolog = false" v-else
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { type } from "@/api/userCenter.js";
import { getFlowVerifyCode, tradeRecordExport } from "@/api/userCenters.js";
import { formatDate } from "../../../utils/date";
import Vue from "vue";
import html2canvas from "html2canvas";
import * as types from "@/store/action-types";
import {
  getHistogram7Day,
  getTotalInAndOut,
  getTransactionList,
} from "@/api/userCenters.js";
import { downloadEleReceipt } from "@/api/Accountopening.js";
import * as echarts from "echarts";
export default {
  props: {},
  data() {
    return {
      istrue: true,
      centerDialogVisible: false,
      total: 0,
      timeList: [],
      btnChangeEnable: true,
      // 刷新
      listLoading: false,
      // 获取类型
      getTypes: {
        priceType: [],
      },
      formBase: {
        tradeType: "",
        pageNum: 1,
        pageSize: 10,
        startTime: "",
        endTime: "",
      },
      // 树状图横轴数轴数据
      xAxis: [],
      yAxis: [],
      xAxis1: [],
      yAxis1: [],
      // 总收入,总支出
      totalIn: 0,
      totalOut: 0,
      // 交易记录
      record: [],
      breadcrumbDto: [
        { path: "/userIndex", title: "个人中心" },
        { path: "/jiaoyi", title: "交易记录" },
      ],
      headerobject: {},
      FlowVerifyCodeDiolog: false,
      flowVerifyForm: {
        FlowVerifyCodeDate: "",
        startTime: "",
        endTime: "",
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      flowVerifyCodeList: [],
      queryCodeBtn: true,
    };
  },
  computed: {},
  created() {
    // 获取类型
    this.getPriceType();
    // 获取收入/支出趋势
    this.getTrend();
    // 获取总收入和总支出
    this.getTotal();
    // 获取交易记录
    this.getTransactionList();
  },
  watch: {
    "formBase.priceType": {
      handler: function (newValue) {
        if (newValue !== "") {
          this.btnChangeEnable = false;
        } else {
          this.btnChangeEnable = true;
        }
      },
      immediate: true,
      deep: true,
    },
    "formBase.time": {
      handler: function (newValue) {
        if (newValue !== "") {
          this.btnChangeEnable = false;
        } else {
          this.btnChangeEnable = true;
        }
      },
      immediate: true,
      deep: true,
    },
    "flowVerifyForm.FlowVerifyCodeDate": {
      handler() {
        this.queryCodeBtn = true;
        this.flowVerifyForm.startTime =
          this.flowVerifyForm.FlowVerifyCodeDate &&
          this.flowVerifyForm.FlowVerifyCodeDate[0]
            ? this.flowVerifyForm.FlowVerifyCodeDate[0]
            : "";
        this.flowVerifyForm.endTime =
          this.flowVerifyForm.FlowVerifyCodeDate &&
          this.flowVerifyForm.FlowVerifyCodeDate[1]
            ? this.flowVerifyForm.FlowVerifyCodeDate[1]
            : "";
      },
    },
  },
  filters: {
    // 时间过滤器
    formatCreateTime(time) {
      let date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
    // 格式化交易类型
    formatType(row) {
      switch (row) {
        case 1:
          return "竞价采购订单支出";
          // eslint-disable-next-line no-unreachable
          break;
        case 2:
          return "竞价采购订单收入";
          // eslint-disable-next-line no-unreachable
          break;
        case 3:
          return "竞价销售订单支出";
          // eslint-disable-next-line no-unreachable
          break;
        case 4:
          return "竞价销售订单收入";
          // eslint-disable-next-line no-unreachable
          break;
        case 5:
          return "挂牌销售订单支出";
          // eslint-disable-next-line no-unreachable
          break;
        case 6:
          return "挂牌销售订单收入";
          // eslint-disable-next-line no-unreachable
          break;
        case 7:
          return "挂牌采购订单支出";
          // eslint-disable-next-line no-unreachable
          break;
        case 8:
          return "挂牌采购订单收入";
          // eslint-disable-next-line no-unreachable
          break;
        case 9:
          return "保证金支付";
          // eslint-disable-next-line no-unreachable
          break;
        case 10:
          return "保证金退还";
          // eslint-disable-next-line no-unreachable
          break;
        case 11:
          return "服务大厅订单收入";
          // eslint-disable-next-line no-unreachable
          break;
        case 12:
          return "服务大厅订单支出";
          // eslint-disable-next-line no-unreachable
          break;
        case 13:
          return "提现";
          // eslint-disable-next-line no-unreachable
          break;
        case 14:
          return "充值";
          // eslint-disable-next-line no-unreachable
          break;
        case 15:
          return "支付违约金";
          // eslint-disable-next-line no-unreachable
          break;
        case 16:
          return "违约金收取";
          // eslint-disable-next-line no-unreachable
          break;
        case 17:
          return "拼团支付预付款";
          // eslint-disable-next-line no-unreachable
          break;
        case 18:
          return "拼团预付款退还";
          // eslint-disable-next-line no-unreachable
          break;
        case 19:
          return "拼团预付款扣除";
          // eslint-disable-next-line no-unreachable
          break;
        case 20:
          return "拼团预付款收取";
          // eslint-disable-next-line no-unreachable
          break;
        case 21:
          return "物流竞价订单支出";
          break;
        case 22:
          return "物流竞价订单收入";
          break;
        case 23:
          return "预付款支付";
          // eslint-disable-next-line no-unreachable
          break;
        case 24:
          return "履约金支付";
          // eslint-disable-next-line no-unreachable
          break;
        default:
          return "未知";
      }
    },
    formatWay(row) {
      switch (row) {
        case 1:
          return "线下支付";
          // eslint-disable-next-line no-unreachable
          break;
        case 2:
          return "线上支付";
          // eslint-disable-next-line no-unreachable
          break;
        case 3:
          return "承兑付款";
          // eslint-disable-next-line no-unreachable
          break;
        default:
          return "未知";
      }
    },
  },
  methods: {
    printDom() {
      let canvasID = document.querySelector("#table");
      html2canvas(canvasID).then((canvas) => {
        let dom = document.body.appendChild(canvas);
        dom.style.display = "none";

        var mywindow = window.open("", "PRINT", "height=400,width=600");
        mywindow.document.write("<html><head><title>打印预览</title>");
        mywindow.document.write("</head><body >");
        mywindow.document.write(
          '<img style="width:100%;" src="' + dom.toDataURL("image/png") + '" />'
        );
        mywindow.document.write("</body></html>");
        mywindow.document.close();
        mywindow.focus(); 
        mywindow.onload = function () {
          mywindow.print();
          mywindow.close();
        };
      });
    },
    handleExportTable() {
      let config = {
        ...this.formBase,
      };
      let enterpriseId = Vue.ls.get(types.enterpriseId);
      config.enterpriseId = enterpriseId;
      tradeRecordExport(config).then((res) => {
        var elink = document.createElement("a");
        elink.download = "交易记录.xls";
        elink.style.display = "none";
        var blob = new Blob([res], { type: "application/vnd.ms-excel" });
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        document.body.removeChild(elink);
      });
    },
    handleCopyVerifyCode(row) {
      let oInput = document.createElement("textarea");
      oInput.value = row.verifyCode;
      document.body.appendChild(oInput);
      oInput.select();
      document.execCommand("Copy");
      oInput.style.display = "none";
      document.body.removeChild(oInput);
      this.$message.success("复制成功");
      // window.open('https://corp.bank.ecitic.com/cotb/m000A0201.html');
    },
    getFlowVerifyList() {
      let config = {
        startTime: this.flowVerifyForm.startTime,
        endTime: this.flowVerifyForm.endTime,
      };
      getFlowVerifyCode(config).then((res) => {
        if (res.code == 0) {
          this.queryCodeBtn = false;
          this.flowVerifyCodeList = res.data?.verifyCodeList || [];
          if (!this.flowVerifyCodeList.length) {
            this.$message("暂无数据");
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    handleQueryCode() {
      this.flowVerifyCodeList = [];
      this.flowVerifyForm = {
        FlowVerifyCodeDate: "",
        startTime: "",
        endTime: "",
      };
      this.FlowVerifyCodeDiolog = true;
    },
    BackClick(e) {
      let flowId = e.flowId;
      let config = {
        flowId,
      };
      downloadEleReceipt(config).then((res) => {
        if (res.code == 0) {
          window.open(res.data);
        }
      });
    },
    handleClick(e) {
      e.tradeTime = e.tradeTime.replace(/T/g, " ");
      this.headerobject = e;
      this.centerDialogVisible = true;
    },
    tradeTypeChange(e) {
      this.isheader = e;
      this.click();
      switch (e) {
        case 13:
          return (this.istrue = false);
          break;
        case 14:
          return (this.istrue = false);
          break;
        default:
          return (this.istrue = true);
      }
    },

    dataURLToBlob(dataurl) {
      //ie 图片转格式
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    //下载
    downloadResult(name) {
      let canvasID = document.querySelector("#table");
      html2canvas(canvasID).then((canvas) => {
        let dom = document.body.appendChild(canvas);
        dom.style.display = "none";
        let blob = this.dataURLToBlob(dom.toDataURL("image/png"));
        let blobUrl = window.URL.createObjectURL(blob);
        if (window.navigator.msSaveBlob) {
          //判断了有该方法即为IE浏览器
          try {
            window.navigator.msSaveBlob(blob, name + ".png");
          } catch (e) {
            console.log(e);
          }
        } else {
          // 谷歌浏览器 创建a标签 添加download属性下载
          const aElement = document.createElement("a"); //动态创建a标签
          aElement.style.display = "none"; //可以设置a标签并不可见
          document.body.appendChild(aElement); //将创建的a标签追加到body内
          aElement.setAttribute("href", blobUrl);
          aElement.setAttribute("download", name + ".png");
          aElement.click();
          document.body.removeChild(aElement);
          window.URL.revokeObjectURL(blobUrl); //释放掉blob对象
        }
        document.body.removeChild(dom);
      });
    },
    cellStyle() {
      return "text-align:center;border-bottom:1px dashed #E5E5E5;";
    },
    // 格式化交易方式
    tradeStatus(row) {
      switch (row.tradeStatus) {
        case 1:
          return "成功";
          // eslint-disable-next-line no-unreachable
          break;
        case 2:
          return "失败";
          // eslint-disable-next-line no-unreachable
          break;
          return "未知";
      }
    },

    // 获取类型
    getPriceType: function () {
      this.getTypes.priceType = type;
      console.log(this.getTypes);
    },
    // 获取趋势
    getTrend() {
      getHistogram7Day({ type: 1 }).then((res) => {
        // console.log(res.data);
        let list = res.data;
        list.forEach((item) => {
          this.xAxis.push(item.item);
          this.yAxis.push(item.VALUE);
        });
        this.drawLine();
      });
      getHistogram7Day({ type: 2 }).then((res) => {
        let list1 = res.data;
        list1.forEach((item) => {
          this.xAxis1.push(item.item);
          this.yAxis1.push(item.VALUE);
        });
        this.drawLine();
      });
    },
    // 获取总收入和总支出
    getTotal() {
      getTotalInAndOut().then((res) => {
        this.totalIn = res.data.totalIn;
        this.totalOut = res.data.totalOut;
      });
    },
    // 获取交易数量
    getTransactionList() {
      this.listLoading = true;
      let enterpriseId = Vue.ls.get(types.enterpriseId);
      this.formBase.enterpriseId = enterpriseId;
      console.log(this.formBase);
      getTransactionList(this.formBase).then((res) => {
        // 获取列表数据
        if (res.code == 0) {
          this.record = res.data.list;
          this.total = res.data.total;
          this.listLoading = false;
        }
      });
    },
    // 绘制柱状图
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = echarts.init(document.getElementById("myChart"));
      let myChart1 = echarts.init(document.getElementById("myChart1"));
      // 绘制图表
      myChart.setOption({
        title: { text: "业绩跟踪-收入趋势(亿元)" },
        tooltip: {},
        xAxis: {
          data: this.xAxis,
          axisLabel: {
            rotate: 0,
            show: true,
            textStyle: {
              fontSize: 11,
              fontFamily: "Source Han Sans CN",
              fontWeight: 400,
              color: "#A9B0B9",
            },
          },
        },
        yAxis: {
          textStyle: {
            fontSize: 11,
            fontFamily: "Source Han Sans CN",
            fontWeight: 400,
            color: "#A9B0B9",
          },
        },
        grid: {
          x: 70,
          y: 50,
          x2: 30,
          y2: 35,
        },
        series: [
          {
            name: "销量",
            type: "bar",
            data: this.yAxis,
            barWidth: 25, //柱子的宽度
            barGap: 36,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#83bff6" },
                { offset: 0.5, color: "#188df0" },
                { offset: 1, color: "#188df0" },
              ]),
            },
          },
        ],
      });
      myChart1.setOption({
        title: { text: "业绩跟踪-支出趋势(亿元)" },
        tooltip: {},
        xAxis: {
          data: this.xAxis1,
          textStyle: {
            fontSize: 11,
            fontFamily: "Source Han Sans CN",
            fontWeight: 400,
            color: "#A9B0B9",
          },
        },
        yAxis: {
          textStyle: {
            fontSize: 11,
            fontFamily: "Source Han Sans CN",
            fontWeight: 400,
            color: "#A9B0B9",
          },
        },
        grid: {
          x: 70,
          y: 50,
          x2: 30,
          y2: 35,
        },
        series: [
          {
            name: "销量",
            type: "bar",
            data: this.yAxis1,
            barWidth: 25, //柱子的宽度
            barGap: 36,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 1, color: "#F96161" },
                // { offset: 0.5, color: "#188df0" },
                { offset: 0, color: "#FF8A8A" },
              ]),
            },
          },
        ],
      });
    },
    pickerfocus(e) {},
    // 查询数据
    click() {
      if (this.timeList.length) {
        let date = new Date(this.timeList[0]);
        let date1 = new Date(this.timeList[1]);
        this.timeList[0] = formatDate(date, "yyyy-MM-dd hh:mm:ss");
        this.timeList[1] = formatDate(date1, "yyyy-MM-dd hh:mm:ss");
        this.formBase.startTime = this.timeList[0];
        this.formBase.endTime = this.timeList[1];
      }

      this.getTransactionList();
    },
    // 改变页码
    changePage(newPage) {
      this.formBase.pageNum = newPage;
      this.getTransactionList();
    },
    // 每页显示信息条数
    handleSizeChange(val) {
      this.formBase.pageSize = val;
      if (this.formBase.pageNum === 1) {
        this.getTransactionList();
      }
    },
    handleCurrentChange(val) {
      this.formBase.pageNum = val;
      this.getTransactionList();
    },
    // 重置操作
    handleResetSearch() {
      this.istrue = true;
      this.formBase.endTime = "";
      this.formBase.startTime = "";
      (this.timeList = []), (this.formBase.tradeType = "");
      this.getTransactionList();
    },
  },
  components: {},
};
</script>
<style lang="scss" scoped>
.export {
  width: calc(100% - 40px);
  margin-left: 20px;
  display: flex;
  justify-content: right;
}
</style>
<style lang="scss">
.table-box {
  min-height: 465px;
}
.name-wrapper {
  span {
    background-color: #faede9;
    border-color: #e5e5e5;
    color: #333333;
  }
}
.table-active {
  span {
    background-color: #f0fbea;
    border-color: #e5e5e5;
    color: #333333;
  }
}
::v-deep .el-breadcrumb__item {
  height: 39px;
  line-height: 39px;
  // padding-top: 10px;
}

.search .el-input__inner {
  height: 34px !important;
  line-height: 34px;
}

.el-icon-bumen {
  width: 38px;
  height: 34px;
  font-size: 35px;
  // color: #f8f8f8;
}

.jiaoyi {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  margin-bottom: 18px;
  margin-top: 20px;
  .headers {
    display: flex;
    font-size: 30px;
    height: 116px;
    border-bottom: 1px dashed #c9c9c9;
    i {
      font-size: 34px;
      color: #7b7b7b;
    }
    .totalIn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      .left {
        .picture {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 83px;
          height: 83px;
          background: #f8f8f8;
          border-radius: 50%;
          margin-right: 19px;
        }
      }
      .right {
        .top {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 35px;
        }
        .bottom {
          font-size: 14px;
          color: #be0202;
          .text {
            font-size: 35px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #be0202;
            line-height: 35px;
          }
        }
      }
    }
    .totalOut {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      .left {
        .picture {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 83px;
          height: 83px;
          background: #f8f8f8;
          border-radius: 50%;
          margin-right: 13px;
        }
      }
      .right {
        .top {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 35px;
        }
        .bottom {
          font-size: 14px;
          color: #be0202;
          .text {
            font-size: 35px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #be0202;
            line-height: 35px;
          }
        }
      }
    }
  }
  .clearfix {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .chart {
    display: flex;
    width: 100%;
    .charts {
      border-left: 3px solid #76b1fe !important;
    }
    #myChart {
      width: 430px;
      height: 290px;
      background: #ffffff;
      border: 1px solid #eeeeee;
      margin-right: 24px;
      margin-top: 30px;
      margin-left: 10px;
    }
    #myChart1 {
      width: 430px;
      height: 290px;
      background: #ffffff;
      border: 1px solid #eeeeee;
      margin-top: 30px;
    }
  }
  .search {
    display: flex;
    margin-top: 57px;
    justify-content: flex-end;
    margin-right: 24px;
    margin-bottom: 20px;
    .select {
      width: 211px;
      height: 34px;
      background: #ffffff;
      // border: 1px solid #d2d2d2;
      border-radius: 4px;
      margin-right: 9px;
      margin-left: 20px;
    }
    .data {
      width: 417px;
      height: 34px;
      background: #ffffff;
      // border: 1px solid #d2d2d2;
      border-radius: 4px;
      margin-right: 7px;
    }
    .btn {
      width: 76px;
      height: 34px;
      background: linear-gradient(90deg, #2079fb, #4cb2fc);
      border-radius: 4px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 8px;
    }
    .canle {
      width: 76px;
      height: 34px;
      background: #ffffff;
      border: 1px solid #d2d2d2;
      border-radius: 4px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #aaaaaa;
      line-height: 8px;
    }
  }
}
.el-card__body {
  padding: 0 !important;
}
.box-card {
  width: 930px;
  margin: 20px;
  border: #d2d2d2 1px solid;
  margin-bottom: 18px;
}

.paymentReceipt {
  table {
    th,
    td {
      border: 1px solid #99948c;
    }
  }
}
</style>
