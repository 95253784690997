export const type =
  process.env.VUE_APP_OPEN_LOGISTICS == "true"
    ? [
        {
          label: "竞价采购订单支出",
          value: 1,
        },
        {
          label: "竞价采购订单收入",
          value: 2,
        },
        {
          label: "竞价销售订单支出",
          value: 3,
        },
        {
          label: "竞价销售订单收入",
          value: 4,
        },
        {
          label: "挂牌销售订单支出",
          value: 5,
        },
        {
          label: "挂牌销售订单收入",
          value: 6,
        },
        {
          label: "挂牌采购订单支出",
          value: 7,
        },
        {
          label: "挂牌采购订单收入",
          value: 8,
        },
        {
          label: "保证金支付",
          value: 9,
        },
        {
          label: "保证金退还",
          value: 10,
        },
        {
          label: "服务大厅订单收入",
          value: 11,
        },
        {
          label: "服务大厅订单支出",
          value: 12,
        },
        {
          label: "提现",
          value: 13,
        },
        {
          label: "充值",
          value: 14,
        },
        {
          label: "支付违约金",
          value: 15,
        },
        {
          label: "违约金收取",
          value: 16,
        },
        // {
        //     label:'拼团预付款支付',
        //     value:17
        // },
        // {
        //     label:'拼团预付款退还',
        //     value:18
        // },
        // {
        //     label:'拼团预付款扣除',
        //     value:19
        // },
        // {
        //     label:'拼团预付款收取',
        //     value:20
        // },
        {
          label: "物流竞价订单支出",
          value: 21,
        },
        {
          label: "物流竞价订单收入",
          value: 22,
        },
        {
          label: "预付款支付",
          value: 23,
        },
        {
          label: "履约金支付",
          value: 24,
        },
      ]
    : [
        {
          label: "竞价采购订单支出",
          value: 1,
        },
        {
          label: "竞价采购订单收入",
          value: 2,
        },
        {
          label: "竞价销售订单支出",
          value: 3,
        },
        {
          label: "竞价销售订单收入",
          value: 4,
        },
        {
          label: "挂牌销售订单支出",
          value: 5,
        },
        {
          label: "挂牌销售订单收入",
          value: 6,
        },
        {
          label: "挂牌采购订单支出",
          value: 7,
        },
        {
          label: "挂牌采购订单收入",
          value: 8,
        },
        {
          label: "保证金支付",
          value: 9,
        },
        {
          label: "保证金退还",
          value: 10,
        },
        {
          label: "服务大厅订单收入",
          value: 11,
        },
        {
          label: "服务大厅订单支出",
          value: 12,
        },
        {
          label: "提现",
          value: 13,
        },
        {
          label: "充值",
          value: 14,
        },
        {
          label: "支付违约金",
          value: 15,
        },
        {
          label: "违约金收取",
          value: 16,
        },
        // {
        //     label:'拼团预付款支付',
        //     value:17
        // },
        // {
        //     label:'拼团预付款退还',
        //     value:18
        // },
        // {
        //     label:'拼团预付款扣除',
        //     value:19
        // },
        // {
        //     label:'拼团预付款收取',
        //     value:20
        // },
        // {
        //     label:'物流竞价订单支出',
        //     value:21
        // },
        // {
        //     label:'物流竞价订单收入',
        //     value:22
        // },
        {
          label: "预付款支付",
          value: 23,
        },
        {
          label: "履约金支付",
          value: 24,
        },
      ];

// // "充值",
//     // "提现",
//     // "保证金支付",
//     // "保证金回退",
//     // "交易订单支付",
//     // "服务订单支付",
//     // "交易订单退款",
//     // "服务订单退款",
//     // "线下支付",
//     // "违约保证金",
//     // "支付预付款",
//     // "预付款退还",
//     // "预付款扣除",
//     "竞价采购订单支出",
//     "竞价采购订单收入",
//     "竞价销售订单支出",
//     "竞价销售订单收入",
//     "挂牌销售订单支出",
//     "挂牌销售订单收入",
//     "挂牌采购订单支出",
//     "挂牌采购订单收入",
//     "保证金支付",
//     "保证金退还",
//     "服务大厅订单收入",
//     "服务大厅订单支出",
//     "提现",
//     "充值",
//     "支付违约金",
//     "违约金收取",
//     "支付预付款",
//     "预付款退还",
//     // "预付款扣除",
//     "预付款收取",
//     // "物流竞价订单支出",
//     // "物流竞价订单收入",
//     // "预付款支付",
//     // "履约金支付"
